import React from 'react'
import { navigate } from 'gatsby'
import classnames from 'classnames'
import Layout from '../components/layout'

const firebase = require("firebase/app")
require("firebase/functions")

class Contact extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    this.setState({ loading: true })
    const contactUs = firebase.functions().httpsCallable('app/contactUs')
    contactUs({
      from: this.state.from,
      message: this.state.message
    }).then(result => {
      navigate('/#contact-ok')
    }).catch(e => {
      this.setState({ error: true, loading: false })
    })

    e.preventDefault()
  }

  render() {
    return (
      <Layout location={this.props.location}>
        <section className={classnames({
          'centered-form': true,
          'loading': this.state.loading
        })}>
          <h2>Contact Us</h2>
          <form onSubmit={this.handleSubmit}>
            <label>
              <span>
                Need help making a map, interactive visualization, or just making sense of your data? Fill in the form below, or email us
                at <a href="mailto:contact@visualperspective.io">contact@visualperspective.io</a>
              </span>
            </label>
            <label>
              <span>Your Email Address</span>
              <input type="email" required name="from" autoFocus onChange={this.handleChange} />
            </label>
            <label>
              <span>Your Message</span>
              <textarea name="message" required onChange={this.handleChange} />
            </label>
            {
              this.state.error ? (
                <label>
                  <span className="error">
                    We ran into an error sending your message. Please email us
                    at <a href="mailto:contact@visualperspective.io">contact@visualperspective.io</a>
                  </span>
                </label>
              ) : (
                <label>
                  <button className='btn' type="submit">Submit</button>
                </label>
              )
            }
          </form>
        </section>
      </Layout>
    )
  }
}

export default Contact
